<template>
  <el-dialog :visible.sync="dialog" :close-on-click-modal="false" :title="title" append-to-body @closed="form = null" width="640px">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" size="small" label-suffix=":" label-width="56px">
          <div class="h sb">
            <!--v-if="$erp.enable()"  -->
            <el-form-item label="账号" prop="username" style="width: 48%;">
              <el-input v-model="form.username" :maxlength="20" />
            </el-form-item>
            <el-form-item label="密码" prop="password" style="width: 48%;" v-if="isAdd">
              <el-input type="password" v-model="form.password" :maxlength="20" />
            </el-form-item>
            <el-form-item label="密码" style="width: 48%;" v-else>
              <el-input type="password" v-model="form.password" :maxlength="20" placeholder="不修改密码请置空" />
            </el-form-item>
          </div>

          <div class="h sb">
            <el-form-item label="姓名" prop="userRealName" style="width: 48%;">
              <el-input v-model="form.userRealName" :maxlength="20" />
            </el-form-item>
            <el-form-item label="电话" prop="phone" style="width: 48%;">
              <el-input v-model="form.phone" :maxlength="11" />
            </el-form-item>
          </div>

          <div class="h sb">
            <el-form-item label="部门" style="width: 48%;" v-if="self">
              <tree-picker v-model="form.deptId" url="api/dept" placeholer="选择部门" @change="handleDeptChange" style="width: 100%;" clearable/>
            </el-form-item>
            <el-form-item label="邮箱" prop="email" style="width: 48%;">
              <el-input v-model="form.email" :maxlength="50" />
            </el-form-item>
          </div>
          <!-- <el-form-item label="岗位" style="width: 48%;">
          <quick-select v-model="form.jobId" filterable placeholder="选择岗位" url="api/job" :params="{deptId: form.deptId}" v-if="form.deptId" style="width: 100%;" />
          <el-input v-else disabled placeholder="请先选择部门" />
          </el-form-item>-->

          <el-form-item label="区域" v-if="self">
            <template v-if="form.deptId">
              <el-input readonly placeholder="正在加载中…" v-if="areaLoading" />
              <el-cascader :options="areas" v-model="form.areaIds" :props="areaProps" :show-all-levels="false" filterable placeholder="不选择时则和部门负责区域一致" clearable style="width:100%" v-else-if="areas && areas.length" />
              <el-input readonly placeholder="与当前部门一样负责所有区域" v-else />
            </template>
            <el-input readonly placeholder="请先选择部门，否则表示负责所有区域" v-else />
          </el-form-item>

          <el-form-item label="角色" prop="_roleIds">
            <quick-select v-model="form._roleIds" filterable url="api/roles/all/byent" multiple :params="{ enterpriseId: targetEnterprise }" display-field="fullName" :pagable="false" placeholder="请选择角色" style="width: 100%;" v-if="special === 'supplier'" />
            <quick-select v-model="form._roleIds" filterable url="api/roles" multiple :params="{ enterpriseId: targetEnterprise }" style="width: 100%;" v-else />
          </el-form-item>

          <el-form-item label="门店" v-if="form.enterpriseId">
            <quick-select v-model="form.shopIds" multiple url="api/shop" :params="{entId: form.enterpriseId}" display-field="shopName" placeholder="请选择门店" filterable clearable style="width: 100%;" />
            <div class="fc-g">不选择门店则作为经销商账号，选择门店将作为门店营业员账号。</div>
          </el-form-item>

          <el-form-item label="状态" prop="enabled" style="width: 48%;">
            <el-radio-group v-model="form.enabled">
              <el-radio :label="true">激活</el-radio>
              <el-radio :label="false">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" size="small" label-width="50px" label-position="right" label-suffix="：">
          <form-info-item label="账号">{{form.username}}</form-info-item>
          <div class="h">
            <form-info-item label="姓名" style="width: 50%;">{{form.userRealName}}</form-info-item>
            <form-info-item label="电话" class="flex">{{form.phone}}</form-info-item>
          </div>
          <div class="h">
            <form-info-item label="部门" style="width: 50%;">{{form.dept ? form.dept.name : ''}}</form-info-item>
            <form-info-item label="邮箱" class="flex">{{form.email}}</form-info-item>
          </div>
          <form-info-item label="角色">{{roleNames}}</form-info-item>
          <form-info-item label="状态">{{form.enabled ? "激活" : "禁用"}}</form-info-item>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { getById, add, edit } from "@/api/user";
import { getAreas } from "@/api/dept";
import { getLevel } from "@/api/role";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
    self: Boolean,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: null,
      level: 3,
      special: null,
      editable: false,
      targetEnterprise: null,
      areas: [],
      areaLoading: false,
      areaProps: {
        value: "id",
        label: "name",
        checkStrictly: true,
        multiple: true,
        expandTrigger: "hover",
        emitPath: false,
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符,",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
        userRealName: [{ required: true, message: "请输入姓名" }],
        email: [
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        phone: [
          { required: true, trigger: "blur" },
          {
            pattern: /^\d{11}$/gi,
            message: "请输入正确的电话号码",
            trigger: "blur",
          },
        ],
        enabled: [{ required: true, message: "状态不能为空", trigger: "blur" }],
        _roleIds: [
          { required: true, message: "请选择角色" },
          { type: "array", min: 1, message: "请选择角色" },
        ],
      },
    };
  },
  mounted() {
    if (!this.$erp.enable()) {
      this.rules.username.push({
        pattern: /^(?!JMJ|JMS|ZYZ|ZYS|sales|SD|D).*/i,
        message: "不能以SD/D/JMJ/JMS/ZYZ/ZYS/sales开头",
        trigger: "blur",
      });
    }
  },
  computed: {
    title() {
      if (this.self) {
        return !this.editable
          ? "查看用户"
          : this.isAdd
          ? "新增用户"
          : "编辑用户";
      } else {
        return this.isAdd ? "新建用户账号" : "编辑用户账号";
      }
    },
    roleNames() {
      return (this.form ? this.form.roles || [] : [])
        .map((o) => {
          return o.name;
        })
        .join("，");
    },
  },
  methods: {
    doSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let fd = JSON.parse(JSON.stringify(this.form));
          fd.roles = fd._roleIds.map((rid) => {
            return { id: rid };
          });
          if (fd.shopIds && fd.shopIds.length) {
            fd.shopId = "," + fd.shopIds.join(",") + ",";
          } else {
            fd.shopId = "";
          }
          delete fd.shopIds;
          delete fd._roleIds;
          if (this.isAdd) {
            this.doAdd(fd);
          } else this.doEdit(fd);
        } else {
          return false;
        }
      });
    },
    doAdd(fd) {
      add(fd)
        .then((res) => {
          this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.dialog = false;
          if (typeof this.$parent.init === "function") {
            this.$parent.init();
          } else {
            this.$parent.$parent.init();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    doEdit(fd) {
      edit(fd)
        .then((res) => {
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.dialog = false;
          // console.log(this.$parent.$parent);
          if (typeof this.$parent.init === "function") {
            this.$parent.init();
          } else {
            this.$parent.$parent.init();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    resetForm(form, entId = null, base = {}, special = null) {
      this.special = special;
      this.targetEnterprise = entId;
      this.editable = true;
      if (form) {
        getById(form.id).then((res) => {
          if (res.areas && res.areas.length) {
            res.areaIds = res.areas.map((o) => {
              return o.id;
            });
          } else {
            res.areaIds = null;
          }
          if (res.roles && res.roles.length) {
            res._roleIds = res.roles.map((o) => {
              return o.id;
            });
          } else {
            res._roleIds = null;
          }
          delete res.areas;
          delete res.roles;
          this.form = res;
          this.handleDeptChange(false);
        });
      } else {
        this.form = Object.assign(
          {
            username: "",
            password: "",
            email: "",
            enabled: true,
            roles: [],
            _roleIds: [],
            jobId: null,
            deptId: "",
            shopId: null,
            areaIds: null,
            phone: null,
            enterpriseId: entId,
          },
          base
        );
      }
      this.dialog = true;
    },
    handleDeptChange(e) {
      if (e !== false) this.form.areaIds = null;
      this.areas = [];
      if (this.self && this.form.deptId) {
        this.areaLoading = true;
        getAreas(this.form.deptId)
          .then((res) => {
            let roots = [],
              ds = [],
              subs = [];
            if (res && res.length) {
              res.forEach((o) => {
                if (
                  o.parentId == null ||
                  res.findIndex((s) => {
                    return s.id === o.parentId;
                  }) < 0
                ) {
                  roots.push(o.id);
                }
              }); // 先找出能成为根节点的区域
              res.forEach((o) => {
                if (roots.indexOf(o.id) >= 0) {
                  ds.push(o);
                } else {
                  subs.push(o);
                }
              }); // 将根区域与其它区域分离
              if (subs.length) {
                let fn = (p) => {
                  p.children = subs.filter((s) => {
                    return s.parentId === p.id;
                  });
                  if (p.children && p.children.length) {
                    p.children.forEach((c) => {
                      fn(c);
                    });
                  } else {
                    p.children = null;
                  }
                };
                ds.forEach((d) => {
                  fn(d);
                });
              }
              this.areas = ds;
            }
          })
          .finally((_) => {
            this.areaLoading = false;
          });
      }
    },
    showDetail(form) {
      if (form) {
        this.editable = false;
        this.form = form;
        this.dialog = true;
      }
    },
  },
};
</script>