<template>
  <el-dialog :visible.sync="dialog" title="重置密码" append-to-body :close-on-click-modal="false" @closed="form = null" width="480px">
    <el-form ref="form" :model="form" :rules="rules" label-width="108px" label-suffix=":" label-position="right" v-if="form">
      <el-form-item label="账号">
        <el-input v-model="form.username" readonly />
      </el-form-item>
      <el-form-item prop="password" label="新登录密码">
        <el-input v-model="form.password" :maxlength="20" placeholder="请输入用户的新登录密码" />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { resetPass } from "@/api/user";

export default {
  data() {
    return {
      saving: false,
      dialog: false,
      form: null,
      rules: {
        password: [
          { required: true, message: "请输入新的登录密码", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.$confirm("您确定要重置该账号的密码吗？", "操作确认", {
            type: "warning"
          }).then(_ => {
            this.saving = true;
            resetPass(this.form)
              .then(res => {
                this.$notify({
                  title: "重置密码成功",
                  type: "success",
                  duration: 2500
                });
                this.dialog = false;
              })
              .finally(_ => {
                this.saving = false;
              });
          });
        });
    },
    resetForm(username) {
      if (username) {
        this.form = { username: username, password: "" };
        this.dialog = true;
      }
    }
  }
};
</script>