<template>
  <div class="v" style="height: 100%;">
    <template v-if="enterprise">
      <div class="head-container">
        <el-select v-model="query.enabled" filterable clearable placeholder="状态" class="filter-item" style="width: 90px" @change="toQuery">
          <el-option :value="true" label="激活" />
          <el-option :value="false" label="禁用" />
        </el-select>
        <el-input v-model="query.blurry" clearable placeholder="输入名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        <div v-permission="['ADMIN','USER_ALL','USER_CREATE']" style="display: inline-block;margin: 0px 2px;">
          <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
      <el-table v-loading="loading" :data="data" height="200px">
        <el-table-column prop="username" label="账号" width="100" />
        <el-table-column prop="userRealName" label="姓名" width="120" />
        <el-table-column label="角色" :formatter="r=>{return showRoles(r.roles)}" />
        <el-table-column prop="phone" label="电话" width="100" />
        <!-- <el-table-column :show-overflow-tooltip="true" prop="email" label="邮箱" min-width="100" /> -->
        <!-- <el-table-column label="部门" min-width="100" show-overflow-tooltip> -->
        <!-- <template slot-scope="scope">{{ scope.row.dept ? scope.row.dept.name:""}}</template>
        </el-table-column>-->
        <el-table-column prop="createTime" label="创建日期" width="135">
          <template slot-scope="scope">
            <span>{{ new Date(scope.row.createTime).format() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.enabled">激活</el-tag>
            <el-tag type="info" v-else>禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['ADMIN','USER_ALL','USER_EDIT','USER_DELETE'])" width="140" fixed="right">
          <div slot-scope="scope" class="row-commands">
            <el-button v-permission="['ADMIN','USER_ALL','USER_EDIT']" size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button v-permission="['ADMIN','USER_ALL','USER_EDIT']" size="mini" type="text" @click="resetPwd(scope.row)" v-if="scope.row.enabled">重置密码</el-button>
            <el-popover v-permission="['ADMIN','USER_ALL','USER_DELETE']" :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </template>
    <div class="fc-g" v-else>请先在左侧选择要进行账号管理的经销商。</div>
    <eForm ref="form" :is-add="isAdd" />
    <reset-pwd ref="pwd" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del } from "@/api/user";
import eForm from "@/views/system/user/form";
import resetPwd from "@/views/system/user/reset-pwd";

export default {
  components: { eForm, resetPwd },
  mixins: [initData],
  props: {
    enterprise: Object,
  },
  data() {
    return {
      query: {
        enabled: null,
        blurry: null,
      },
    };
  },
  computed: {
    title() {
      if (this.enterprise) return this.enterprise.name + " - 账号列表";
      return "账号列表";
    },
  },
  watch: {
    enterprise: "toQuery",
  },
  methods: {
    checkPermission,
    beforeInit() {
      if (!this.enterprise) return false;
      this.url = "api/users";
      this.params = {
        sort: "id,desc",
        enterpriseId: this.enterprise.enterpriseId,
        blurry: this.query.blurry,
        enabled: this.query.enabled,
      };
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    add() {
      this.isAdd = true;
      this.$refs.form &&
        this.$refs.form.resetForm(
          null,
          this.enterprise.enterpriseId,
          null,
          "supplier"
        );
    },
    edit(data) {
      this.isAdd = false;
      let fd = JSON.parse(JSON.stringify(data));
      fd._roleIds = data.roles.map((r) => {
        return r.id;
      });
      this.$refs.form &&
        this.$refs.form.resetForm(
          fd,
          this.enterprise.enterpriseId,
          null,
          "supplier"
        );
    },
    resetPwd(data) {
      this.$refs.pwd && this.$refs.pwd.resetForm(data.username);
    },
    showRoles(data) {
      let n = "";
      if (data.length > 1) {
        data.forEach((o) => {
          n += o.name + ",";
        });
      } else if (data.length == 1) {
        data.forEach((o) => {
          n = o.name;
        });
      }
      return n;
    },
  },
};
</script>